<template>
    <div
        v-if="projects && projects.length !== 0"
        class="tree-navigation"
    >
        <div
            id="virtual-projects"
            :class="{with_top_banner: getTrialPeriod}"
            :remain="amountOfTheProjects"
            :scrollelement="el"
            :size="34"
        >
            <div
                v-for="categoryParent in projects"
                :id="generateProjectDomId(categoryParent.project_id)"
                :key="categoryParent.id"
                class="tree-navigation-link"
                data-cy="project"
            >
                <div
                    class="project-and-tags-wrap"
                    :class="itemParentProjectAndTagsWrapClasses(categoryParent)"
                    @click="parentClickHandler(categoryParent, $event)"
                >
                    <parent-project
                        :get-opened-projects-keys="getOpenedProjectsKeys"
                        :project="categoryParent"
                        :set-opened-projects-keys="setOpenedProjectsKeys"
                        :add-tag-tooltip="addTagTooltip"
                        :handle-add-tag-or-sub-project-click="handleAddTagOrSubProjectClick"
                        :initialTab="initialTab"
                        :kiTab="kiTab"
                    />
                </div>
                <div class="parent_child_container">
                    <div
                        v-if="categoryParent.tags && categoryParent.tags.length && getOpenedProjectsKeys.includes(categoryParent.id)"
                        data-cy="tags-amount-wrap"
                    >
                        <div
                            v-for="tag in categoryParent.tags"
                            :id="generateTagDomId(tag.id)"
                            :key="tag.id"
                            class="project_tag_child"
                            :class="itemChildClasses(tag)"
                            data-cy="project-child"
                        >
                            <div class="child-project-name-and-tags">
                                <div
                                    class="project__name-wrapper"
                                    :class="getTagClasses(tag)"
                                    @click.stop="addActiveTagHandler(categoryParent, tag)"
                                >
                                    <span class="kw kw-tag" />
                                    <span
                                        class="project__name project__name_child project__name_child_item project__tag_item"
                                        @mouseover="getTooltipForText(tag.name, $event)"
                                    >
                                        {{ tag.name }} ({{ tag.keywords_count }})
                                    </span>
                                    <div
                                        v-if="!isViewOnlyUser && !categoryParent.is_demo_project"
                                        class="tag_actions_block"
                                    >
                                        <span
                                            class="kw kw-edit"
                                            @click="toggleProjectEditTag"
                                        />
                                        <span
                                            class="kw kw-trash-2"
                                            @click="handleDeleteTagClik"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="isChildrenDisplayed(categoryParent)">
                        <div
                            v-for="categoryChild in categoryParent.children"
                            :id="generateProjectDomId(categoryChild.project_id)"
                            :key="categoryChild.id"
                            data-cy="project-child"
                            class="sub_project"
                        >
                            <div
                                class="child-project-name-and-tags "
                                :class="itemChildClasses(categoryChild)"
                            >
                                <div
                                    v-if="(categoryChild.tags && categoryChild.tags.length)"
                                    class="subprojects-projects-list"
                                    @click.stop.exact="setOpenedProjectsKeys({project:categoryChild})"
                                >
                                    <span :class="subprojectsClasses(categoryChild)" />
                                </div>
                                <div
                                    class="project__name-wrapper "
                                    @click.stop.exact="parentClickHandler(categoryChild, $event, true)"
                                >
                                    <router-link
                                        class="project__name project__name_child project__name_child_item child_inner_tag_item"
                                        :to="{path: `/projects/${encodeURIComponent(categoryChild.id)}${initialTab ? `?tab=${initialTab}${kiTab ? `&ki=${kiTab}` : ''}` : ''}`}"
                                    >
                                        <span
                                            class="child_inner_text"
                                            data-cy="sub-project-name"
                                            @mouseover="getTooltipForText(categoryChild.name, $event)"
                                        >
                                            {{ categoryChild.name }}
                                        </span>
                                        <span
                                            class="project__count"
                                            data-cy="project-keyword-count"
                                            v-text="`(${categoryChild.keywords_count.ACTIVE || 0})`"
                                        />
                                    </router-link>
                                </div>
                                <span
                                    v-if="!isViewOnlyUser && !categoryChild.is_demo_project"
                                    data-item-id="addTag"
                                    class="project_add_tag_icon_block"
                                    @click="handleAddTagClick(categoryChild)"
                                >
                                    <span
                                        class="project-add-tag-icon kw kw-plus"
                                        data-cy="sub-project-add-tag-icon"
                                        @mouseover="addTagTooltip($event, $t('add-new-tag'))"
                                    />
                                </span>
                            </div>
                            <div class="parent_child_container">
                                <div
                                    v-if="isTagsDisplayed(categoryChild)"
                                    data-cy="tags-amount-wrap"
                                >
                                    <div
                                        v-for="tag in categoryChild.tags"
                                        :id="generateTagDomId(tag.id)"
                                        :key="tag.name"
                                        class="project_tag_child"
                                        :class="itemChildClasses(tag)"
                                        data-cy="project-child"
                                    >
                                        <div class="child-project-name-and-tags">
                                            <div
                                                class="project__name-wrapper"
                                                :class="getTagClasses(tag)"
                                                @click.stop="addActiveTagHandler(categoryChild, tag)"
                                            >
                                                <span class="kw kw-tag" />
                                                <span
                                                    class="project__name project__name_child project__name_child_item project__tag_item"
                                                    @mouseover="getTooltipForText(tag.name, $event)"
                                                >
                                                    {{ tag.name }} ({{ tag.keywords_count }})
                                                </span>
                                                <div
                                                    v-if="!isViewOnlyUser && !categoryChild.is_demo_project"
                                                    class="tag_actions_block"
                                                >
                                                    <span
                                                        class="kw kw-edit"
                                                        @click="toggleProjectEditTag"
                                                    />
                                                    <span
                                                        class="kw kw-trash-2"
                                                        @click="handleDeleteTagClik"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <sidebar-peloader v-if="!getActiveProjectsLoaded" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { MOBILE_WIDTH_RESOLUTION_PHONE } from '@/constants';
import ParentProject from './ParentProject';
import ConfirmProjectTagDeleteModal from 'components/modals/ConfirmProjectTagDeleteModal';
import AddTagOrSubProject from 'components/modals/AddTagOrSubProject';
import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';

export default {
  name: 'TreeNavigation',
  components: {
    'parent-project': ParentProject,
  },
  mixins: [ToolTipRenderMixin],
  props: {
    projects: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      amountOfTheProjects: 34,
      el: null,
      showAllTags: null,
    }
  },
  computed: {
    ...mapGetters([
      'getActiveProjectsLoaded',
      'getActiveTag',
      'getCheckedProjects',
      'getCurrentProject',
      'getProjectProperty',
      'getOpenedProjectsKeys',
      'isViewOnlyUser',
      'getTrialPeriod',
    ]),
    initialTab() {
      return this.$route.query?.tab ? this.$route.query.tab : '';
    },
    kiTab() {
      return this.$route.query?.ki ? this.$route.query.ki : '';
    }
  },
  mounted() {
    this.setVirtualTableHeight();
    this.$nextTick(() => window.addEventListener('resize', this.setVirtualTableHeight));
  },
  beforeMount() {
    if (!this.$route.query.tag) {
      this.openParentProjectsOnInit(this.getProjectProperty('parent'));
    }
  },

  beforeDestroy() {
    this.$nextTick(() => window.removeEventListener('resize', this.setVirtualTableHeight));
  },
  methods: {
    ...mapActions([
      'addActiveTag',
      'fetchMetrics',
      'fetchKeywords',
      'resetTagFilter',
      'setSideBarOpened',
      'toggleAddSubProject',
      'toggleProjectAddTags',
      'toggleProjectEditTag',
      'setOpenedProjectsKeys',
      'setAllOpenedProjectsKeys',
    ]),
    /**
     * @description - expand parent project if they are checked, deleted or includes current project
     * @param {string} parentName - parent project name of current project
     */

    openParentProjectsOnInit(parentName) {
      const projectsForOpen = this.projects.reduce((acc, cur) => {
        if (cur.deleted || this.getCheckedProjects.includes(cur.id)) {
          !acc.includes(cur.id) && acc.push(cur.id);
        }

        if (parentName) {
          const id = this.projects.find(g => g.name === parentName)?.id;
          if (id) {
            !acc.includes(id) && acc.push(id);
          }
        }

        return acc;
      }, []);

      this.setAllOpenedProjectsKeys(projectsForOpen);
    },
    parentClickHandler(parent, event) {
      if (!this.getOpenedProjectsKeys.includes(parent.id)) {
        this.setOpenedProjectsKeys({ project: parent });
      }
      if (parent.deleted) {
        this.$toastr.i(this.$t('optional-add-keyword'));
        return;
      }
      if (event.target.getAttribute('data-item-id') !== 'addTag') {
        if (this.getActiveTag?.id) {
          this.addActiveTag({});
        }
        this.handleLinkClick(parent);
      }

    },
    setVirtualTableHeight() {
      const wraperHeight = window.innerHeight;
      const offset = 243;
      const amount = Math.ceil((wraperHeight - offset) / 34);
      this.amountOfTheProjects = amount;
    },

    async addActiveTagHandler(project, tag) {
      const { id } = project;
      if (this.getActiveTag.id === tag.id) return;

      if (this.whenGoToProject(id, tag.id)) {
        this.goToProject(project, false, true, tag.id);
      }
      // this.$router.replace({ name: this.$route.name , query: { tag: tag.id }})
      this.addActiveTag(tag);
    },
    /**
     * @description - this method checks if routing to project should be made
     * @param {string} id - id of the project of (de)selected tag
     * @returns {boolean}
     */
    whenGoToProject(id, tagId) {
      return this.$route.name === 'Overview'
        || !this.getCurrentProject
        || this.getCurrentProject.id !== id
        || (tagId && this.getActiveTag.id !== tagId);
    },

    handleLinkClick(project, hide = true) {
      const { id } = project;

      if (id.includes('deleted')) return;
      if (hide && window.screen.width < MOBILE_WIDTH_RESOLUTION_PHONE) {
        this.setSideBarOpened(false);
      }
    },
    async goToProject(project, hide = true, skipFetch = false, tag) {
      const { id } = project;
      const initialTab = this.$route.query?.tab;
      const kiTab = this.$route.query?.ki;

      const query = {
        ...(tag ? { tag } : {}),
        ...(initialTab ? { tab: initialTab } : {}),
        ...(kiTab ? { ki: kiTab } : {}),
      }
      if (id.includes('deleted')) return;

      this.$router.push({ name: 'keywordList', params: { id, skipFetch }, query });

      if (hide && window.screen.width < MOBILE_WIDTH_RESOLUTION_PHONE) {
        this.setSideBarOpened(false);
      }
    },
    itemChildClasses({ id, name }) {
      return {
        'project_child': true,
        'project_active': this.getProjectProperty('id') === id,
        'active_tag': this.getActiveTag.id === id && this.getActiveTag.name === name,
      };
    },
    itemParentProjectAndTagsWrapClasses({ id }) {
      return {
        'project_active': this.getProjectProperty('id') === id,
      }
    },
    addTagTooltip(event, text) {
      return this.tooltip({ text, event });
    },
    getTagClasses() {
      return {
        project_tag_name: true
      }
    },

    subprojectsClasses({ id }) {
      return {
        'kw kw-chevron-up': true,
        'is-open': this.getOpenedProjectsKeys.includes(id)
      }
    },
    handleAddTagClick(project) {
      const currentProject = this.getCurrentProject;
      const needToGoToProject = !currentProject || (currentProject.parent !== project.name && project.parent !== currentProject.name && project.project_id !== currentProject.project_id);
      if (needToGoToProject) {
        this.goToProject(project);
        this.setOpenedProjectsKeys({ project });
      }

      this.toggleProjectAddTags(project);
    },
    handleAddTagOrSubProjectClick(project) {
      this.$modal.show(
        AddTagOrSubProject,
        {
          handleAddTag: () => this.handleAddTagClick(project),
          handleAddSubProject: () => this.toggleAddSubProject(project),
        },
        {
          height: 'auto',
          name: 'AddTagOrSubProject',
          classes: 'v--modal',
          width: document.body.offsetWidth < 650 ? '95%' : 600,
        }
      );
    },
    handleDeleteTagClik() {
      this.$modal.show(
        ConfirmProjectTagDeleteModal,
        { type: 'deleteProjectTtag' },
        {
          classes: 'v--modal',
          draggable: '.custom-modal_draggable',
          height: 'auto',
          name: 'DeleteProjectTag',
          width: document.body.offsetWidth < 649 ? '90%' : 370,
        },
      );
    },
    generateProjectDomId(id) {
      return `project_${id}`;
    },
    generateTagDomId(id) {
      return `tag_${id}`;
    },

    isTagsDisplayed(categoryChild) {
      return categoryChild.tags && categoryChild.tags.length && this.getOpenedProjectsKeys.includes(categoryChild.id);
    },

    isChildrenDisplayed(categoryParent) {
      return ((categoryParent.children && categoryParent.children.length !== 0) && this.getOpenedProjectsKeys.includes(categoryParent.id));
    }
  },
};
</script>

<style lang="scss">
  #virtual-projects {
    overflow: auto !important;
    @extend .custom-scroll;
    margin-right: 3px;
    height: calc(100vh - 310px);

    &.with_top_banner {
      height: calc(100vh - 360px)
    }
  }

  .tree-navigation {
    width: 100%;
    height: 100%;

    .project-and-tags-wrap {
      &.project_active {
        background: #E8EDF2;
        border-radius: 5px 0 0 5px;

        .project__name {
          color: kw-color(kw-blue, 1);
          font-weight: 500;
          text-decoration: none;

          &:before {
            background-color: kw-color(kw-blue, 1);
          }
        }
      }

      &:hover,
      &:focus {
        background-color: #E8EDF2;
      }

      &:active {
        background-color: #E8EDF2;

        .project__name {
          color: kw-color(kw-blue, 1);
          font-weight: 500;
          text-decoration: none;
          font-size: 14px;

          &:before {
            background-color: #265596;
          }
        }
      }

      &:focus {
        outline: none;
      }
    }

    .sidebar-tags-amount-wrap {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      min-width: 35px;
      padding-right: 18px;

      .tags-amount {
        font-size: 14px;
        margin-right: 2px;
        padding-top: 1px;
      }
    }

    .tags-are-opened {
      .tags-amount {
        color: kw-color(kw-blue, 1);
      }

      .kw-tag {
        color: kw-color(kw-blue, 1);
        transform: rotate(-90deg);
        font-weight: bold;
      }
    }

    .project-list-main-tags-wrap {
      background: kw-color(kw-white, 1);
      box-shadow: 0 2px 4px rgba(31, 63, 97, 0.03);
      border-radius: 4px;
      position: relative;
    }

    .only-one-filter {
      color: kw-color(kw-blue, 1);
      font-size: 14px;
      margin-left: auto;
      visibility: hidden;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .child-project-name-and-tags {
      display: flex;
      max-width: 361px;
      flex-grow: 1;
      align-items: center;
      height: 100%;
    }

    .tag-checkbox-wrap {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 30px;
      max-height: 22px;
      padding-right: 8px;

      .checkbox {
        left: 8px;
        top: 3px;
      }

      &:hover {
        background-color: #F0F2F5;
      }

      label {
        background: #F5F6F7;
        border: 1px solid #CBD6E9;
        border-radius: 4px;
        font-size: 11px;
        text-align: center;
        text-transform: lowercase;
        padding: 0px 6px;
        color: #265596;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 7px;
        max-width: 132px;
        margin-bottom: unset;
        line-height: 15px;
        max-height: 16px;
        transition: .5s all;

        &:hover {
          background: #CBD6E8;
          color: #265596;
          cursor: pointer;
        }
      }
    }

    .triangle {
      background: kw-color(kw-white, 1);
      transform: matrix(0.71, -0.68, 0.73, 0.71, 0, 0);
      display: block;
      width: 12px;
      height: 12px;
      position: absolute;
      top: -5px;
      left: 88%;
      outline: unset;
    }

    .toggle-all-tags {
      position: relative;
      display: flex;
      align-items: center;
      height: 22px;
      margin-top: 6px;
      padding-left: 30px;

      .checkbox {
        left: 8px;
        top: 3px;
      }

      label {
        font-size: 12px;
        line-height: 14px;
        font-weight: normal;
        color: #909399;
        margin-bottom: unset;
      }
    }

    .toggle-all-tags:hover {
      background-color: #F0F2F5;
    }

    .tag-checkbox-wrap:hover .only-one-filter {
      visibility: visible;
    }

    .subprojects {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-grow: 1;
      justify-content: flex-start;
      max-width: 58px;
      position: relative;
      transition: all .5s;
    }

    .main-tags-wrap {
      position: relative;
      max-width: 36px;
      flex: 1;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      margin-left: 12px;
    }

    .tree-navigation-link {
      margin-right: 8px;
      text-decoration: none;

      .project_child:first-child {
        .project__name_child:before {
          height: 22px;
          top: -5px;
        }
      }
    }

    .tags-list-wrap {
      @extend .custom-scroll;
      max-height: 249px;
      overflow: auto;
      padding-bottom: 6px;
    }

    .tree-navigation-link:focus {
      outline: unset;
    }

    .project_child {
      height: 35px;
      position: relative;
      padding-left: 30px;

      .project-list-main-tags-wrap {
        margin-right: 18px;
        margin-left: 9px;
        position: relative;
      }

      .child_inner_text {
        display: inline-block;
        vertical-align: bottom;
        width: max-content;
      }

      .triangle {
        width: 12px;
        top: -6px;
      }

      .project__name-wrapper {
        a.project__name_child_item {
          padding-left: 8px;
          padding-right: 13px;
          display: flex;
        }
      }

      .project__count {
        margin-left: 5px;
      }

      &:last-child a:after {
        border: none;
      }

      &:hover {
        background-color: #E8EDF2;
        border-radius: 5px 0 0 5px;
      }

      &.project_active, &.active_tag {
        background: #E8EDF2;
        border-radius: 5px 0 0 5px;

        .project__name {
          color: kw-color(kw-blue, 1);
          font-weight: 500;
          text-decoration: none;
        }
      }

      &:active {
        background-color: rgba(23, 107, 250, 0.08);

        .project__name {
          color: kw-color(kw-blue, 1);
          font-weight: 500;
          text-decoration: none;
        }
      }

      &:focus {
        outline: transparent;
      }
    }

    .project_tag_child {
      padding-left: 46px;
    }
  }

  .project__name-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    flex-grow: 1;
    overflow: hidden;
    justify-content: center;
    outline: transparent !important;

    .checkbox {
      position: relative !important;
    }

    span {
      outline: transparent !important;
    }

    /deep/ small {
      font-size: 75%;
    }
  }

  .project__name {
    color: kw-color(kw-black, 1);
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    outline: transparent !important;
    text-decoration: none;
    vertical-align: top;

    &:hover,
    &:focus {
      color: inherit;
    }
  }

  .project__name_child {
    display: inline-block;
    width: 100%;

    .child_inner_text {
      @import '~sass/cutting-name';
    }

    &:hover {
      background: transparent;
      text-decoration: none;
    }

    &:before {
      border-bottom: solid 1px #CCCCCC;
      border-left: solid 1px #CCCCCC;
      bottom: 6px;
      content: '';
      left: 16px;
      height: 29px;
      position: absolute;
      top: -12px;
      width: 4px;
    }

    &:after {
      border-left: solid 1px #CCCCCC;
      bottom: -14px;
      content: '';
      height: 1000vh;
      left: 16px;
      position: absolute;
      width: 8px;
    }

  }

  .project_tag_child {
    .project__name_child {
      &:before {
        left: 35px;
      }

      &:after {
        left: 35px;
      }
    }
  }

  .kw-tag {
    color: #828F9C;
    font-weight: bold;
    transform: rotate(-96deg);
  }

  .project_child, .project_parent {
    .project_add_tag_icon_block {
      width: 36px !important;
      height: 100%;
      cursor: pointer;
      transition: opacity 0.5s;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 1 !important;
      }
    }

    .project-add-tag-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 7px;
      font-weight: 700;
      width: 16px !important;
      height: 16px;
      background-color: kw-color(kw-blue, 1);
      color: kw-color(kw-white, 1);
      border-radius: 2px;

    }

    &.project_active {
      .project_add_tag_icon_block {
        opacity: 0.4;
      }

    }

    &:last-child {
      .project__name_child:after {
        display: none;
      }

    }

  }

  .sub_project {
    &:last-child {
      .child_inner_tag_item:after {
        bottom: 18px;
      }
    }
  }

  .project_active {
    .project_child, .project_parent {
      .project_add_tag_icon_block {
        opacity: 0.5;
      }

    }
  }

  .project__tag_item {
    @import '~sass/cutting-name';
    padding-left: 9px;
  }

  .parent_child_container {
    overflow: hidden;
  }

  .subprojects-projects-list {
    cursor: pointer;

  }

  .kw-chevron-up {
    color: kw-color(kw-blue, 1);
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    transform: scaleY(-1);

    &.is-open {
      transform: scaleY(1);
    }
  }

  .tag_actions_block {
    display: flex;
    padding-right: 12px;
    opacity: 0;
    transition: opacity 0.5s;
    height: 100%;
    align-items: center;

    &:hover {
      opacity: 1 !important;
    }

    span {
      margin-left: 12px;
      color: kw-color(kw-blue, 1);

      &.kw {
        font-weight: 700;

        &.kw-trash-2 {
          font-size: 15px;
        }
      }
    }

    span:first-child {
      margin: 0;
    }
  }

  .active_tag {
    .tag_actions_block {
      opacity: 0.5;
    }
  }
</style>
