<template>
    <upgrade-for-access v-if="isFreePlan" :title="$t('upgrade-to-integrate-gsc.title')">
        <div v-html="$t('upgrade-to-integrate-gsc.desc')" />
    </upgrade-for-access>

    <div v-else class="keyword-intelligence">
        <div class="nav_content">
            <ul class="nav nav-tabs">
                <li
                    v-for="(tab, index) in tabs"
                    :key="tab.value"
                    :class="{ active: isTabActive(index) }"
                    @click="setActiveTab(index)"
                >
                    {{ tab.label }}
                </li>
            </ul>
        </div>

        <div class="content-wrapper">
            <component :is="activeComponent" />
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import CompetitorsKeywords from './CompetitorsKeywords.vue';
import RelatedKeywords from './RelatedKeywords.vue';
import Suggestions from './Suggestions.vue';
import UpgradeForAccess from '@/components/common/UpgradeForAccess.vue';

export default {
    name: "KeywordIntelligence",
    components: {
        'suggestions': Suggestions,
        'related': RelatedKeywords,
        'upgrade-for-access': UpgradeForAccess,
        'competitors': CompetitorsKeywords,
    },
    data(){
        return {
            tabs: [
                {
                    value: 'suggestions',
                    label: this.$t('suggestions')
                },
                {
                    value: 'related',
                    label: this.$t('related-keywords')
                },
                {
                    value: 'competitors',
                    label: this.$t('competitors-keywords')
                }
            ],
            activeTab: 0,
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentPlan',
            'getGSCProfileCurrentConnection',
            'getCurrentProject'
        ]),
        activeComponent() {
            return this.tabs[this.activeTab]?.value || this.tabs[0].value;
        },
        isFreePlan() {
            return this.getCurrentPlan?.subscription?.is_free_plan;
        },
        currentTabKey() {
            return this.tabs[this.activeTab]?.value;
        },
    },
    created() {
        const tabFromQuery = this.$route.query?.ki;
        if (tabFromQuery) {
            const index = this.tabs.findIndex(item => item.value === tabFromQuery);
            if (index) {
                this.activeTab = index;
            }
        } else {
            this.updateQuery(0);
        }
    },
    methods: {
        ...mapActions(['setGSCFilters', 'deleteGSCFilterItem']),
        isTabActive(index) {
            return this.activeTab === index;
        },
        setActiveTab(index) {
            this.updateQuery(index);
            this.activeTab = index;
        },
        updateQuery(index) {
            const currentQuery = { ...this.$route.query };
            this.$router.replace({ query: { ...currentQuery, ki: this.tabs[index].value }});
        },
        toggleFilters() {
            this.$refs.filtersDropdown.toggleDropdownOpen();
        },
        closeFiltersDropdown() {
            if (this.$refs.filtersDropdown?.getIsDropdownOpened) {
                this.$refs.filtersDropdown.closeDropdown();
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '~sass/partials/new-nav-tabs';
    
    .nav_content {
        position: sticky;
        top: 177px;
        background-color: kw-color(kw-white, 4);
        z-index: 15;
        padding-top: 15px;
        padding-bottom: 10px;
        margin-top: -15px;
        .nav-tabs {
            @extend .nav-tabs_filled;
            margin: 0;
            box-shadow: 0px 2px 2px rgba(31, 63, 97, 0.1);
            padding-right: 0!important;
            background-color: kw-color(kw-white, 1)!important;

            li {
                font-family: Inter, Helvetica,'Helvetica Neue', Arial, sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                padding: 8px 16px;
                cursor: pointer;
                &.active {
                    color: kw-color(kw-white, 1);
                }
            }
        }
    }
    .content-wrapper {
        width: 100%;
        min-height: 50vh;
    }
    .keyword-intelligence {
        width: 100%;
        position: relative;
        /deep/ .filter-main {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
        }
        /deep/ .filter-row {
            position: relative;
            width: 50px;
            margin-left: auto;
            .filter-gsc__wrapper{
                display: flex;
                width: 100%;
                justify-content: flex-end;
                &>.kw-button {
                    width: 32px;
                    height: 32px;
                    .kw {
                        font-weight: 900;
                    }
                }
                .custom_dropdown_body {
                    z-index: 499;
                }
            }
        }
        /deep/ .filter-gsc__wrapper > .custom_dropdown_body > .v-context {
            max-width: 514px;
            width: 100%;
            transform: translateX(-90%);
            overflow-y: visible;
            @media screen and (max-height: 900px) {
                bottom: 58px;
            }
            @media screen and (max-width: 550px) {
                max-width: 90%;
            }
        }
    }
</style>